<template>
  <svg
    width="172"
    height="172"
    viewBox="0 0 172 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M150.4 0H21.2C9.5 0 0 9.5 0 21.2V150.4C0 162.1 9.5 171.6 21.2 171.6H150.4C162.1 171.6 171.6 162.1 171.6 150.4V21.2C171.6 9.5 162.1 0 150.4 0Z"
      fill="#003056"
    />
    <path
      d="M123.2 85.6C123.2 74.9 114.9 65.9 104.2 65C103.3 64.9 102.5 64.8 101.6 64.8C95.9 64.4 91.5 60.3 90.6 54.6C89.8 49.4 93 44 98.2 42C105.2 39.4 113.5 43.9 114 52.6C114.1 55 114.4 57.5 115.2 59.8C118.3 67.9 124.1 72.7 132.8 73.8C133.9 73.9 134.9 74 136 74.1C141.9 74.5 146.5 79.1 146.8 85.3C147.1 91.2 143.2 96 137.6 97.3C135.7 97.7 133.8 97.6 131.9 97.9C123 99.1 115.3 107 114.4 115.9C114.3 117 114.2 118.1 114.1 119.2C113.7 125.5 108.4 130.5 102.1 130.4C95.9 130.3 90.8 125.3 90.6 119.1C90.4 113.7 88.9 108.9 85.3 104.9C81.2 100.3 76 98 69.9 97.7C66.9 97.6 64.2 96.7 61.9 94.8C58.2 91.7 56.8 86.6 58.5 81.9C60.1 77.3 64.4 74.2 69.3 74.1C75.7 74 80.7 78.5 81.3 84.9C81.5 87.2 81.6 89.4 82.2 91.7C86.6 106.9 105.9 111.9 117.1 100.8C121.2 96.5 123.3 91.5 123.2 85.6Z"
      fill="#00A69C"
    />
    <path
      d="M48.2 84.7C48.2 91.5 50.2 96.3 54 100.3C57.7 104.2 62.4 106.4 67.9 106.8C70.2 106.9 72.4 107.2 74.5 108.3C82.1 112.2 82.5 121.8 77.6 126.8C73.4 131 66.1 131.6 61.4 127.2C59.2 125.2 58.1 122.6 57.8 119.7C57.6 118 57.5 116.4 57.2 114.7C55.7 106.3 48.6 99.4 40.5 97.9C38.6 97.6 36.8 97.6 34.9 97.3C30.4 96.6 27.2 94.1 25.7 89.9C24.1 85.5 24.9 81.4 28.1 77.9C30.4 75.4 33.3 74.3 36.6 74.2C43.7 74 49.4 71.1 53.7 65.3C56.1 62 57.4 58.3 57.6 54.3C57.7 52.1 58 50 59 47.9C61.2 43.3 65.8 41 70.4 41.4C76.2 41.9 80 46 80.9 50.7C81.2 52 81.2 53.4 81.3 54.8C81.9 64.4 89.7 72.7 99.3 73.9C100.5 74 101.6 74.1 102.8 74.2C107.6 74.7 111.2 76.9 113 81.4C114.9 86.1 114 90.5 110.5 94.1C106 98.8 98.6 98.6 94.3 94.5C91.6 92 90.5 88.9 90.5 85.4C90.3 79.7 88.2 74.9 84.2 71C76.9 63.7 65.2 62.9 57 69C51.1 73.1 48.3 78.9 48.2 84.7Z"
      fill="#26A9E0"
    />
  </svg>
</template>

<script>
export default {
  name: "ProjectLogo",
};
</script>
